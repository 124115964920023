<template>
  <tbody>
    <tr>
      <td rowspan="2" style="border-bottom: none">主任签发</td>
      <td rowspan="2" colspan="2" style="border-bottom: none">
        <auditshow
          :infoData="data"
          taskKey="省规划办发文卡-主任签发"
          @callback="auditshowBack"
        />
      </td>
      <td>科研管理部意见</td>
      <td colspan="2">
        <auditshow
          :infoData="data"
          taskKey="省规划办发文卡-科研管理部意见"
          @callback="auditshowBack"
        />
      </td>
    </tr>
    <tr>
      <td>规划办秘书组意见</td>
      <td colspan="2">
        <auditshow
          :infoData="data"
          taskKey="省规划办发文卡-规划办秘书处意见"
          @callback="auditshowBack"
        />
      </td>
    </tr>

    <tr>
      <td rowspan="3" style="border-bottom: none">副主任意见</td>
      <td rowspan="3" colspan="2" style="border-bottom: none">
        <auditshow
          :infoData="data"
          taskKey="省规划办发文卡-副主任意见"
          @callback="auditshowBack"
        />
      </td>
      <td>会签意见</td>
      <td colspan="2">
        <a-input-search
          class="select_search"
          readOnly
          v-model="data.huiqianName"
          placeholder="请选择会签人"
          @click="onSearchs"
          @search="onSearchs"
          v-if="data.status == 0 || data.status == 3"
        />
        <auditshow
          v-if="data.status == 2 || data.status == 1"
          :infoData="data"
          taskKey="省规划办发文卡-会签意见"
          @callback="auditshowBack"
        />
      </td>
    </tr>
    <tr>
      <td>核稿</td>
      <td colspan="2">
        <a-input-search
          class="select_search"
          readOnly
          v-model="data.hegaoName"
          placeholder="请选择核稿人"
          @click="onSearch"
          @search="onSearch"
          v-if="data.status == 0 || data.status == 3"
        />
        <auditshow
          v-if="data.status == 2 || data.status == 1"
          :infoData="data"
          taskKey="省规划办发文卡-核稿"
          @callback="auditshowBack"
        />
      </td>
    </tr>
    <tr>
      <td style="border-bottom: none">拟稿</td>
      <td colspan="2" style="border-bottom: none; text-align: left">
        <a-button
          v-if="data.status == 0 || data.status == 3"
          type="link"
          @click="showWps"
          >{{ data.status == 0 ? "填写正文" : "编辑正文" }}</a-button
        >
        <a-button v-else type="link" @click="showWps(data.print == 1)"
          >查看正文</a-button
        >
        <a-popconfirm
          title="提交后数据不可更改，是否确认？"
          @confirm="handleSubmit(1)"
          v-if="data.status == 0 || data.status == 3"
        >
          <a-button type="link" style="margin-right: 10px">提交</a-button>
        </a-popconfirm>

        <span v-if="data.status > 0">{{ data.otherExtension.remark }}</span>

        <a-modal
          title="选择用户"
          v-model="selectuservisible"
          width="70%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <selectuser @callback="selectuserCallback" />
        </a-modal>
        <a-modal
          title="选择用户"
          v-model="selectusersvisible"
          width="70%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <selectuser :multi="true" @callback="selectusersCallback" />
        </a-modal>
      </td>
    </tr>
  </tbody>
</template>
<script>
import selectuser from "../components/searchuser.vue";
export default {
  name: "guihuaban",
  data() {
    return {
      selectuservisible: false,
      selectusersvisible: false,
    };
  },
  props: {
    data: {
      type: Object,
    },
  },
  components: {
    selectuser,
  },
  watch: {
    data(v) {
      this.data = v;
    },
  },
  methods: {
    auditshowBack() {
      this.$emit("callback");
    },
    handleSubmit() {
      if (this.data.huiqian == null || this.data.huiqian == "") {
        this.$message.error("请选择会签人");
        return;
      }

      if (this.data.hegao == null || this.data.huiqian == "") {
        this.$message.error("请选择核稿人");
        return;
      }

      let extensionSettingData = {};
      extensionSettingData["node_省规划办发文卡-核稿"] = {
        targetUsers: this.data.hegao,
        count: 1,
      };
      extensionSettingData["node_省规划办发文卡-会签意见"] = {
        targetUsers: this.data.huiqian,
        count: this.data.huiqian.split(",").length,
      };

      this.$emit("callback", extensionSettingData);
    },
    onSearch() {
      this.selectuservisible = true;
    },

    onSearchs() {
      this.selectusersvisible = true;
    },
    selectusersCallback(user) {
      this.data.huiqian = "";
      this.data.huiqianName = "";
      if (user) {
        this.data.huiqian = user.map((u) => u.userName).join(",");
        this.data.huiqianName = user.map((u) => u.realName).join(",");
      }
      this.selectusersvisible = false;
    },
    selectuserCallback(user) {
      this.data.hegao = user.userName;
      this.data.hegaoName = user.realName;
      this.selectuservisible = false;
    },
    showWps() {
      if (!this.data.number) {
        this.$emit("callback", null, true);
      }
      if (this.data.title == "") {
        this.$message.error("请输入标题");
        return;
      }
      if (this.data.dispatchOrg == "") {
        this.$message.error("请输入主送单位");
        return;
      }
      let _number = this.data.number;
      let reg = /(.+)-(.+)-(.+)/;
      if (!reg.test(_number)) {
        this.$message.error("请正确输入发文编号");
        return;
      }
      if (this.data.time == undefined) {
        this.$message.error("请选择发文日期");
        return;
      }
      let templateId = "fawenkaGuiHuaBan";
      let fileId = localStorage.getItem("publicFileId");
      let openType = "create";
      if (fileId != null && fileId != "") {
        openType = "edit";
        templateId = fileId;
      }
      if (this.data.contentId && this.data.contentId != "") {
        localStorage.setItem("publicFileId", this.data.contentId);
        openType = "edit";
        templateId = this.data.contentId;
      }

      if (
        this.data.status == 3 &&
        this.data.contentId == localStorage.getItem("publicFileId")
      ) {
        openType = "create";
        templateId = "fawenkaGuiHuaBan";
        localStorage.removeItem("publicFileId");
      }

      if (this.data.print == 1) {
        openType = "readOnly";
      }

      let innerData = {
        id: this.data.id,
        status: this.data.status,
        name: _number.split("-")[0],
        year: _number.split("-")[1],
        number: _number.split("-")[2],
        title: this.data.title,
        headTitle: this.data.dispatchOrg,
        date: this.data.time.format("YYYY年MM月DD日"),
      };
      localStorage.setItem("innerData", JSON.stringify(innerData));
      const jump = this.$router.resolve({
        name: "wpsedit",
        query: {
          //要传的参数
          templateId: templateId,
          fileType: "public",
          openType: openType,
          fileName: "省教科院发文卡_" + _number.replaceAll("-", "_"),
          printType: "custom_fawen",
        },
      });

      window.open(jump.href, "_blank");
    },
  },
};
</script>
